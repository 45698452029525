import request from './request'

export default {
    toLogin: data => request.post('/login', data),
    logout: data => request.post('/logout', data),
    getAccountList: data => request.get('/advertise/account/list', { params: data }),
    getMediaList: data => request.get('/advertise/channelType/list', { params: data }),
    deleteMedia: data => request.delete('/advertise/channelType/' + data),
    editMedia: data => request.put('/advertise/channelType', data),
    addMedia: data => request.post('/advertise/channelType', data),
    getCustomerList: data => request.get('/advertise/client/list', { params: data }),
    addCustomer: data => request.post('/advertise/client', data),
    deleteCustomer: data => request.delete(`/advertise/client/${data}`),
    editCustomer: data => request.put('/advertise/client', data),
    getDictValueList: data => request.get(`/system/dict/data/type/${data}`),
    getUserList: data => request.get('/system/user/list', { params: data }),
    getDepartmentList: data => request.get('/system/user/deptTree', { params: data }),
    getDeptList: data => request.get('/system/dept/list', { params: data }),
    addDept: data => request.post('/system/dept', data),
    updateDept: data => request.put('/system/dept', data),
    deleteDept: data => request.delete(`/system/dept/${data}`),
    getRoleList: data => request.get('/system/role/list', { params: data }),
    getJobList: data => request.get('/system/post/list', { params: data }),
    addJob: data => request.post('/system/post', data),
    updateJob: data => request.put('/system/post', data),
    deleteJob: data => request.delete(`/system/post/${data}`),
    addUser: data => request.post('/system/user', data),
    deleteUser: data => request.delete(`/system/user/${data}`),
    editUser: data => request.put('/system/user/', data),
    getMenuList: data => request.get('/system/menu/list', { params: data }),
    getMenuTreeList: data => request.get('/system/menu/treeselect', { params: data }),
    addRole: data => request.post('/system/role', data),
    roleMenuTreeselect: roleId => request.get('/system/menu/roleMenuTreeselect/' + roleId),
    getRole: roleId => request.get('/system/role/' + roleId),
    updateRole: data => request.put('/system/role', data),
    deleteRole: data => request.delete(`/system/role/${data}`),
    deleteMenu: data => request.delete(`/system/menu/${data}`),
    addMenu: data => request.post('/system/menu', data),
    updateMenu: data => request.put('/system/menu', data),
    getRouters: data => request.get('/getRouters'),
    getUserInfo: data => request.get('/getInfo'),
    addAccount: data => request.post('/advertise/account', data),
    editAccount: data => request.put('/advertise/account', data),
    getAgentList: data => request.get('/advertise/channelValue/list', { params: data }),
    addAgent: data => request.post('/advertise/channelValue', data),
    updateAgent: data => request.put('/advertise/channelValue', data),
    deleteAgent: data => request.delete(`/advertise/channelValue/${data}`),
    recharge: data => request.post('/advertise/account/topUp', data),
    clearAccountBalance: data => request.post('/advertise/account/clearBalance', data),
    deleteAccount: data => request.delete(`/advertise/account/${data}`),
    importGoogleAccount: data => {
        const fd = new FormData()
        fd.append('file', data)
        return request.post('/advertise/googleImport/importGoogle', fd)
    },
    importGoogleOldAccount: data => {
        const fd = new FormData()
        fd.append('file', data)
        return request.post('/advertise/googleOldImport/importGoogleOld', fd)
    },
    importFacebookAccount: data => {
        const fd = new FormData()
        fd.append('file', data)
        return request.post('/advertise/facebook/importFacebook', fd)
    },
    importBingAccount: data => {
        const fd = new FormData()
        fd.append('file', data)
        return request.post('/advertise/bingImport/importBing', fd)
    },
    importTiktokAccount: data => {
        const fd = new FormData()
        fd.append('file', data)
        return request.post('/advertise/tiktokImport/importTiktok', fd)
    },
    importFacebookNoLimitAccount: data => {
        const fd = new FormData()
        fd.append('file', data)
        return request.post('/advertise/facebookLimit/importFacebookLimit', fd)
    },
    importTaboolaAccount: data => {
        const fd = new FormData()
        fd.append('file', data)
        return request.post('/advertise/taboolaImport/importTaboola', fd)
    },
    importOutbrainAccount: data => {
        const fd = new FormData()
        fd.append('file', data)
        return request.post('/advertise/outbrainImport/importOutbrain', fd)
    },
    importYandexAccount: data => {
        const fd = new FormData()
        fd.append('file', data)
        return request.post('/advertise/yandexImport/importYandex', fd)
    },
    importTwitterAccount: data => {
        const fd = new FormData()
        fd.append('file', data)
        return request.post('/advertise/twitterImport/importTwitter', fd)
    },
    getRechargeList: data => request.get('/advertise/accountRecharge/list', { params: data }),
    getWalletRechargeList: data => request.get('/advertise/walletRecharge/list', { params: data }),
    walletRecharge: data => request.post('/advertise/walletRecharge/clientRecharge', data),
    walletRechargeByAdmin: data => request.post('/advertise/walletRecharge/adminRecharge', data),
    accountRecharge: data => request.post('/advertise/accountRecharge/accountClientRecharge', data),
    accountRechargeByAdmin: data => request.post('/advertise/accountRecharge/adminAccountRecharge', data),
    getBillList: data => request.get('/advertise/bill/list', { params: data }),
    addBill: data => request.post('/advertise/bill', data),
    editBill: data => request.put('/advertise/bill', data),
    deleteBill: data => request.delete(`/advertise/bill/${data}`),
    getDictList: data => request.get('/system/dict/type/list', { params: data }),
    getDictValues: data => request.get('/system/dict/data/list', { params: data }),
    addDictValue: data => request.post('/system/dict/data', data),
    editDictValue: data => request.put('/system/dict/data', data),
    deleteDictValue: data => request.delete(`/system/dict/data/${data}`),
    addDict: data => request.post('/system/dict/type', data),
    editDict: data => request.put('/system/dict/type', data),
    deleteDict: data => request.delete(`/system/dict/type/${data}`),
    getAccountFunds: data => request.get('/advertise/accountBalanceRecord/list', { params: data }),
    getWalletFunds: data => request.get("/advertise/clientBalanceRecord/list", { params: data }),
    getFeeList: data => request.get('/advertise/fee/list', { params: data }),
    addFee: data => request.post('/advertise/fee', data),
    editFee: data => request.put('/advertise/fee', data),
    deleteFee: data => request.delete(`/advertise/fee/${data}`),
    editWalletBalance: data => request.post('/advertise/clientWallet/upClientWallet', data),
    getCardList: data => request.get('/advertise/bank/list', { params: data }),
    addCard: data => request.post('/advertise/bank', data),
    editCard: data => request.put('/advertise/bank', data),
    deleteCard: data => request.delete(`/advertise/bank/${data}`),
    getComplaintList: data => request.get('/advertise/appeal/list', { params: data }),
    addComplaint: data => request.post('/advertise/appeal', data),
    editComplaint: data => request.put('/advertise/appeal', data),
    deleteComplaint: ids => request.delete(`/advertise/appeal/${ids}`),
    getIndexPageStaticis: data => request.get('/advertise/home/getHomeStatistics', { params: data }),
    getIndexPageEcharts: data => request.get('/advertise/home/getAccountOverview', { params: data }),
    getIndexRankList: data => request.get('/advertise/home/getAccountRanking', { params: data }),
    addWarrant: data => request.post('/advertise/accountAuthorize', data),
    walletRechargeAuth: data => request.post('/advertise/walletRecharge/rechargeAudit', data),
    accountRechargeAuth: data => request.post('/advertise/accountRecharge/rechargeAudit', data),
    getWalletHistoryList: data => request.post('/advertise/walletRecharge/getWalletStatistics', data),
    requestOpenAccount: data => request.post('/advertise/accountApply', data),
    getAccountOpenList: data => request.get('/advertise/accountApply/list', { params: data }),
    authAccountOpen: data => request.post('/advertise/accountApply/AccountApplyReview', data),
    getAccountBalanceStaticis: data => request.get('/advertise/account/getAccountBalanceStatistics', { params: data }),
    getRechargeHandleFee: data => request.post('/advertise/accountRecharge/getAccountHandlingFee', data),
    getAccountRechargeStatistics: data => request.post('/advertise/accountRecharge/getAccountRechargeStatistics', data),
    editAccountApply: data => request.put('/advertise/accountApply', data),
    editAccountName: data => request.post('/advertise/account/changenAccountName', data),
    getChangeNameList: data => request.get('/advertise/AccountChangename/list', { params: data }),
    processChangeName: data => request.post('/advertise/AccountChangename/changeNameReview', data)
}